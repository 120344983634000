/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  appId,
  apiUrl,
  cookieConfig,
  CountryTxt,
  headerconfig,
  dineinId,
} from "../Helpers/Config";
import axios from "axios";
import cookie from "react-cookies";

/* import modules */
import { showAlert } from "../Helpers/SettingHelper";
var Parser = require("html-react-parser");
var qs = require("qs");

class Checkingqr extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let QRId =
      typeof this.props.match.params.QRId != "undefined"
        ? this.props.match.params.QRId
        : "";
    console.log(QRId, "QRIdQRIdQRId");
    axios
      .get(
        apiUrl +
          "outlets/getqrcodedetails?app_id=" +
          appId +
          "&qrcode_id=" +
          QRId,
        headerconfig
      )
      .then((result) => {
        console.log(result.data);
        if (result.data.status === "ok") {
          var outletDetails = result.data.result_set;
          var outlet_name = outletDetails.outlet_name;
          var outlet_id = outletDetails.outlet_id;
          var outlet_unit_number1 = outletDetails.outlet_unit_number1;
          var outlet_unit_number2 = outletDetails.outlet_unit_number2;
          var outlet_address_line1 = outletDetails.outlet_address_line1;
          var outlet_address_line2 = outletDetails.outlet_address_line2;
          var outlet_postal_code = outletDetails.outlet_postal_code;
          var outlet_dine_tat = outletDetails.outlet_dine_tat;
          var order_primary_id = outletDetails.qrcode_order_primary;
          var outlet_local_url = outletDetails.outlet_local_url;

          if (outlet_unit_number1 != "" && outlet_unit_number2 != "") {
            var unitTxt =
              ", #" + outlet_unit_number1 + "-" + outlet_unit_number2;
            var orderHandled =
              outlet_name +
              " " +
              outlet_address_line1 +
              " " +
              outlet_address_line2 +
              unitTxt +
              ", " +
              CountryTxt +
              " " +
              outlet_postal_code;
          } else if (outlet_unit_number1 != "" || outlet_unit_number2 != "") {
            var unitTxt =
              outlet_unit_number1 != ""
                ? ", #" + outlet_unit_number1
                : ", #" + outlet_unit_number2;
            var orderHandled =
              outlet_name +
              " " +
              outlet_address_line1 +
              " " +
              outlet_address_line2 +
              unitTxt +
              ", " +
              CountryTxt +
              " " +
              outlet_postal_code;
          } else {
            var orderHandled =
              outlet_name +
              " " +
              outlet_address_line1 +
              " " +
              outlet_address_line2 +
              ", " +
              CountryTxt +
              " " +
              outlet_postal_code;
          }
          cookie.save("defaultAvilablityId", dineinId, cookieConfig);
          cookie.save("orderOutletId", outlet_id, cookieConfig);
          cookie.save("orderOutletName", outlet_name, cookieConfig);
          cookie.save("orderPostalCode", outlet_postal_code, cookieConfig);
          cookie.save("orderTAT", outlet_dine_tat, cookieConfig);
          cookie.save("orderHandled", orderHandled, cookieConfig);
          cookie.save("orderHandledByText", orderHandled, cookieConfig);
          cookie.save(
            "orderTableNo",
            outletDetails.tm_table_number,
            cookieConfig
          );
          cookie.save("qr_id", QRId, cookieConfig);
          if (order_primary_id !== "" && order_primary_id !== null) {
            cookie.save(
              "dinein_order_primary_id",
              order_primary_id,
              cookieConfig
            );
          }
          if (outlet_local_url !== "" && outlet_local_url !== null) {
            cookie.save("api_url", outlet_local_url, cookieConfig);
          }

          this.props.history.push("/");
        } else {
          showAlert("Error", "Your QR Code link has been expired. Please Try.");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          this.props.history.push("/");
        }
      });
  }

  render() {
    return <div id="dvLoading"></div>;
  }
}

Checkingqr.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(Checkingqr);
