/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_ORDER_DETAIL, SET_ORDER_DETAIL } from "../actions";
import { appId, apiUrl, headerconfig } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetOrderDetail = function* () {
  yield takeEvery(GET_ORDER_DETAIL, workerGetOrderDetail);
};

function* workerGetOrderDetail({ orderId, timeID }) {
  try {
    var time_id = "";
    if (
      timeID !== "" &&
      typeof timeID !== undefined &&
      typeof timeID !== "undefined"
    ) {
      time_id = "&timeID=" + timeID;
    }
    var usercondition = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      usercondition = "&customer_id=" + cookie.load("UserId");
    }
    const uri =
      apiUrl +
      "reports/order_history?app_id=" +
      appId +
      "&local_order_no=" +
      orderId +
      usercondition +
      time_id;
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_ORDER_DETAIL, value: resultArr });
  } catch {
    console.log("Get Order Detail Failed");
  }
}
