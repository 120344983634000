/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import moment from "moment";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {
  appId,
  apiUrl,
  dineinId,
  currencySymbol,
  cookieConfig,
  headerconfig,
} from "../Helpers/Config";
import {
  stripslashes,
  showLoader,
  getCalculatedAmount,
  showAlert,
  removePromoCkValue,
  showPrices,
} from "../Helpers/SettingHelper";
import {
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
} from "../../actions";
import loadingImage from "../../common/images/loading_popup.gif";
import tickImage from "../../common/images/tick_popup.png";
import crossImg from "../../common/images/cross1.png";
import warningImg from "../../common/images/warning.svg";
import productImg from "../../common/images/noimg-400x400.jpg";
var qs = require("qs");
var Parser = require("html-react-parser");
class Confirmorder extends Component {
  constructor(props) {
    super(props);
    /* Rounding to the nearest 15 minute interval */
    const start = moment();
    const remainder = 15 - (start.minute() % 15);
    const currentTime = moment(start).add(remainder, "minutes");
    /* To change the date after 2 PM */
    /*var CurrentDate = this.calcTime('Singapore', '+8');*/
    var qr_id =
      cookie.load("qr_id") !== "" &&
      typeof cookie.load("qr_id") !== undefined &&
      typeof cookie.load("qr_id") !== "undefined"
        ? cookie.load("qr_id")
        : "";
    var dinein_order_primary_id =
      cookie.load("dinein_order_primary_id") !== "" &&
      typeof cookie.load("dinein_order_primary_id") !== undefined &&
      typeof cookie.load("dinein_order_primary_id") !== "undefined"
        ? cookie.load("dinein_order_primary_id")
        : "";

    this.state = {
      qr_id: qr_id,
      order_primary_id: dinein_order_primary_id,
      globalSettings: [],
      overAllcart: [],
      cartItems: [],
      cartDetails: [],
      cartStatus: "",
      cartTotalItmCount: 0,
      promotion_applied: "",
      promotion_type: "",
      promotion_amount: "",
      promoIs_delivery: "",
      paymentmodevalue: "COD",
      validateimage: loadingImage,
      placingorderimage: loadingImage,
      completingpaymentimage: loadingImage,
      orderValidFail: 0,
      cod_payment_enable: 0,
      postorder_triggered: "no",
      updateCartResponse: [],
      startMsg: 0,
      gift_message: "",
    };
  }

  componentWillMount() {
    /*this.props.getCartDetail();*/
  }

  componentWillReceiveProps(nextProps) {
    $("body").removeClass("cart-items-open");
    $(".hcartdd_trigger").removeClass("active");
    $(".hcart_dropdown").removeClass("open");

    if (this.state.globalSettings !== nextProps.settingsArr) {
      var codPayment = 1;
      var paymentmode = "Cash";
      this.setState({
        globalSettings: nextProps.settingsArr,
        cod_payment_enable: codPayment,
        paymentmodevalue: paymentmode,
      });
    }

    if (this.state.overAllcart !== nextProps.overAllcart) {
      this.setState({
        overAllcart: nextProps.overAllcart,
        cartItems: nextProps.cartItems,
        cartDetails: nextProps.cartDetails,
        cartStatus: nextProps.cartStatus,
        cartTotalItmCount: nextProps.cartTotalItmCount,
      });
    }

    if (this.state.updateCartResponse !== nextProps.updateCartResponse) {
      if (Object.keys(nextProps.updateCartResponse).length > 0) {
        this.setState(
          { updateCartResponse: nextProps.updateCartResponse },
          function () {
            var Response = nextProps.updateCartResponse;
            if (Object.keys(Response).length > 0) {
              if (Response[0].status === "error") {
                if (this.state.startMsg === 1) {
                  this.handleShowAlertFunct("Error", Response[0].message);
                  this.setState({ startMsg: 0 });
                }
              }
            }
          }
        );
      }
    }
  }

  handleShowAlertFunct(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentDidMount() {}

  componentDidUpdate() {
    this.checkOutAuthentication();
    var modevalue = this.state.paymentmodevalue;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      this.props.cartItems,
      promoTionArr
    );
    var subTotalAmount = parseFloat(calculatedAmount["cartSubTotalAmount"]);
    var grandTotalAmount = parseFloat(calculatedAmount["grandTotalAmount"]);
    if (subTotalAmount > 0 && grandTotalAmount === 0) {
      modevalue = "promotion";
    }
    if (this.state.paymentmodevalue !== modevalue) {
      this.setState({ paymentmodevalue: modevalue });
    }

    var cartDetailsArr = this.state.cartDetails;
    var settingsArr = this.state.globalSettings;
    if (
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined &&
      Object.keys(cartDetailsArr).length > 0 &&
      Object.keys(settingsArr).length > 0 &&
      this.state.postorder_triggered === "no"
    ) {
      this.setState(
        { postorder_triggered: "yes", getDateTimeFlg: "yes" },
        function () {
          this.postOrder("initial", "Yes");
        }.bind(this)
      );
    }
  }

  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  checkOutAuthentication() {
    var orderOutletId =
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
        ? cookie.load("orderOutletId")
        : "";
    var avilablityId =
      cookie.load("defaultAvilablityId") != "" &&
      cookie.load("defaultAvilablityId") != undefined
        ? cookie.load("defaultAvilablityId")
        : "";

    if (orderOutletId === "" || avilablityId === "") {
      showAlert("Error", "Sorry!. Your order outlet detail is empty.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/menu");
      return false;
    }

    if (this.state.cartStatus === "failure") {
      showAlert("Error", "Sorry!. Your cart is empty.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/menu");
      return false;
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
  };
  loadCartOverallData() {
    var cartDetailsArr = this.state.cartDetails;
    if (Object.keys(cartDetailsArr).length > 0) {
      $("#dvLoading").fadeOut(1000);

      var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
      var calculatedAmount = getCalculatedAmount(
        this.state.globalSettings,
        this.props.zonedetails,
        cartDetailsArr,
        this.props.cartItems,
        promoTionArr
      );

      return (
        <div className="checkout-right-body-section">
          <div className="cart_table">
            <div className="cart_body">
              <div className="overall-parent">
                <div className="order-details-with-clear">
                  <h5>Your Items</h5>
                  <a
                    href="/"
                    onClick={this.clearAllCartItm.bind(this)}
                    className="hclear_cart"
                    title="CLEAR CART"
                  >
                    CLEAR CART
                  </a>
                </div>
                <div className="product-details-parent">
                  {this.loadCartList()}
                </div>
              </div>
            </div>

            <div className="cart_footer">
              <div className="cart_row">
                <div className="row-replace">
                  <div className="col-sm-cls text-left">
                    <p className="text-uppercase">SUBTOTAL</p>
                  </div>
                  <div className="col-sm-cls text-right">
                    <span>
                      {showPrices(calculatedAmount["cartSubTotalAmount"])}
                    </span>
                  </div>
                </div>
              </div>

              {parseFloat(calculatedAmount["voucherDiscountAmount"]) > 0 && (
                <div className="cart_row gst-row">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">Voucher Discount Amount</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        -
                        {showPrices(
                          parseFloat(
                            calculatedAmount["voucherDiscountAmount"]
                          ).toFixed(2)
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {parseFloat(calculatedAmount["orderGstAmount"]) > 0 && (
                <div className="cart_row gst-row">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">
                        GST ({calculatedAmount["orderDisplayGst"]} %)
                      </p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        {showPrices(
                          parseFloat(
                            calculatedAmount["orderGstAmount"]
                          ).toFixed(2)
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className="cart_row grant-total-cls">
                <div className="row-replace">
                  <div className="col-sm-cls text-left">
                    <p className="text-uppercase">Total</p>
                  </div>
                  <div className="col-sm-cls text-right">
                    <span>
                      {showPrices(calculatedAmount["grandTotalAmount"], "Y")}
                    </span>
                  </div>
                </div>

                {parseFloat(cartDetailsArr.cart_special_discount) > 0 && (
                  <div class="member-discount-total">
                    * {cartDetailsArr.cart_special_discount_type}
                    {showPrices(cartDetailsArr.cart_special_discount)} Applied
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  loadCartList() {
    var cartItemsArr = this.state.cartItems;
    if (Object.keys(cartItemsArr).length > 0) {
      return cartItemsArr.map((product, index) => (
        <div
          className="cart_row product-details"
          id={"rowcartid-" + product.cart_item_id}
          key={index}
        >
          <div className="row-replace">
            <div className="col-sm-cls cart_left">
              <div className="cart_img">
                {product.cart_item_product_image !== "" ? (
                  <img src={product.cart_item_product_image} alt="" />
                ) : (
                  <img src={productImg} alt="" />
                )}
              </div>
              <div className="cart_info text-left">
                <h4>{stripslashes(product.cart_item_product_name)}</h4>

                {product.cart_item_voucher_id !== "" &&
                  product.cart_item_voucher_id != null && (
                    <span className="voucher-discount-applied">
                      Discount Applied
                    </span>
                  )}
                {this.loadModifierItems(
                  product.cart_item_type,
                  product.modifiers,
                  product.set_menu_component
                )}

                {product.cart_item_special_notes !== "" && (
                  <p className="help-block">
                    {stripslashes(product.cart_item_special_notes)}
                  </p>
                )}

                {parseFloat(product.cart_item_promotion_discount) > 0 && (
                  <span class="member-discount-desc">
                    {showPrices(product.cart_item_promotion_discount)}{" "}
                    {product.cart_item_promotion_type} discount Applied
                  </span>
                )}
              </div>
            </div>
            <div className="col-sm-cls cart_right text-right">
              <div className="cart_price">
                <p>{showPrices(product.cart_item_total_price)}</p>
              </div>

              {product.cart_item_voucher_product_free != 1 ? (
                <div className="qty_bx">
                  <span
                    className="qty_minus"
                    onClick={this.updateCartQty.bind(this, product, "decr")}
                  >
                    -
                  </span>
                  <input type="text" value={product.cart_item_qty} readOnly />
                  <span
                    className="qty_plus"
                    onClick={this.updateCartQty.bind(this, product, "incr")}
                  >
                    +
                  </span>
                </div>
              ) : (
                <div className="qty_bx free_product">
                  <span className="qty_minus">-</span>
                  <input type="text" value={product.cart_item_qty} readOnly />
                  <span className="qty_plus">+</span>
                </div>
              )}
            </div>
          </div>
          <a
            href="/"
            onClick={this.deleteCartItm.bind(this, product)}
            className="cart_remove"
          >
            <img src={crossImg} alt="" />
          </a>
        </div>
      ));
    }
  }

  updateCartQty(itemArr, type) {
    var productId = itemArr.cart_item_product_id;
    var cartItemId = itemArr.cart_item_id;
    var cartQty = itemArr.cart_item_qty;
    var totalItmCount = this.props.cartTotalItmCount;
    var orderVoucherId = itemArr.cart_voucher_order_item_id;
    showLoader("rowcartid-" + cartItemId, "Idtext");

    if (type === "decr") {
      cartQty = parseInt(cartQty) - 1;
      if (parseInt(totalItmCount) === 0) {
      } else if (parseInt(cartQty) === 0) {
        this.props.deleteCartDetail(cartItemId);
      } else {
        this.props.updateCartDetail(
          productId,
          cartItemId,
          cartQty,
          orderVoucherId
        );
      }
    } else {
      cartQty = parseInt(cartQty) + 1;
      this.props.updateCartDetail(
        productId,
        cartItemId,
        cartQty,
        orderVoucherId
      );
    }
    this.setState({ startMsg: 1 });
  }

  deleteCartItm(itemArr, event) {
    event.preventDefault();
    var cartItemId = itemArr.cart_item_id;
    showLoader("rowcartid-" + cartItemId, "Idtext");
    this.props.deleteCartDetail(cartItemId);
  }
  clearAllCartItm(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#warning-delete-popup",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });
  }
  clearCartItm(event) {
    event.preventDefault();
    showLoader("cart_body", "class");
    this.props.destroyCartDetail();
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice =
          modVlPrice > 0
            ? " <b>(+" + currencySymbol + modVlPrice + ")</b>"
            : "";
        temp_html +=
          "<p><b>" + modName + ": </b>" + modval + newModVlPrice + "</b></p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            "  " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        } else {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        }
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice =
            comboPrice > 0
              ? " <b>(+" + currencySymbol + comboPrice + ")</b>"
              : "";
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice =
          modValPrice > 0
            ? " <b>(+" + currencySymbol + modValPrice + ")</b>"
            : "";
        html +=
          "<p><b>" +
          modName +
          ":</b> </p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  getProductIdsDet(cartItems) {
    var product_cartid = "";
    if (Object.keys(cartItems).length > 0) {
      for (var key in cartItems) {
        if (
          product_cartid !== "" &&
          cartItems[key].cart_item_product_id !== ""
        ) {
          product_cartid += ";";
        }
        product_cartid +=
          cartItems[key].cart_item_product_id +
          "|" +
          cartItems[key].cart_item_total_price +
          "|" +
          cartItems[key].cart_item_qty;
      }
    }

    return product_cartid;
  }

  payCash(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });
    axios
      .get(
        apiUrl +
          "outlets/getqrcodedetails?app_id=" +
          appId +
          "&qrcode_id=" +
          this.state.qr_id,
        headerconfig
      )
      .then((result) => {
        console.log(result.data);
        var order_primary_id = "";
        if (result.data.status === "ok") {
          order_primary_id = result.data.result_set.qrcode_order_primary;
        }
        var condirmOrder = "no";
        if (
          order_primary_id !== "" &&
          order_primary_id !== null &&
          order_primary_id !== "0"
        ) {
          cookie.save(
            "dinein_order_primary_id",
            order_primary_id,
            cookieConfig
          );
          condirmOrder = "yes";
        }
        this.setState({ order_primary_id: order_primary_id }, function () {
          this.postOrder(1, "", condirmOrder);
        });
      });
  }

  /* show online payment mode loading */
  onlinePaymentLoading() {
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      this.props.cartItems,
      promoTionArr
    );
    var grandTotal = parseFloat(calculatedAmount["grandTotalAmount"]);

    if (this.state.paymentmodevalue !== "Cash" && grandTotal > 0) {
      return (
        <div className="process_col">
          <div className="process_left">
            <img src={this.state.validateimage} />
          </div>
          <div className="process_right">
            <h5>Processing</h5>
            <p>Validating card information.</p>
          </div>
        </div>
      );
    }
  }
  /**/

  /* show online payment mode loading */
  orderBCLoading() {
    return (
      <div className="process_col">
        <div className="process_left">
          <img src={this.state.placingorderimage} />
        </div>
        <div className="process_right">
          <h5>Processing</h5>
          <p>Placing your order now.</p>
        </div>
      </div>
    );
  }
  /**/

  /* show online payment mode loading */
  amountCaptureLoading() {
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      this.props.cartItems,
      promoTionArr
    );
    var grandTotal = parseFloat(calculatedAmount["grandTotalAmount"]);

    if (this.state.paymentmodevalue !== "Cash" && grandTotal > 0) {
      return (
        <div className="process_col">
          <div className="process_left">
            <img src={this.state.completingpaymentimage} />
          </div>
          <div className="process_right">
            <h5>Processing</h5>
            <p>Completing your online payment.</p>
          </div>
        </div>
      );
    }
  }
  /**/

  /* this fuction used to post order to biz panel */
  postOrder(paymentMode, validation = "No", confirmOrder = "no") {
    var products = [];
    var row = this.state.cartItems.map(
      function (item, i) {
        var modifierdata = [];
        var comboData = [];
        var ComboLen = item.set_menu_component.length;
        var modifierLen = item.modifiers.length;
        if (item.cart_item_type === "Modifier" && modifierLen > 0) {
          for (var l = 0; l < modifierLen; l++) {
            var modifierVal = [];
            modifierVal.push({
              modifier_value_id:
                item.modifiers[l].modifiers_values[0].cart_modifier_id,
              modifier_value_qty:
                item.modifiers[l].modifiers_values[0].cart_modifier_qty,
              modifier_value_name:
                item.modifiers[l].modifiers_values[0].cart_modifier_name,
              modifier_value_price:
                item.modifiers[l].modifiers_values[0].cart_modifier_price,
            });
            modifierdata.push({
              modifier_id: item.modifiers[l].cart_modifier_id,
              modifier_name: item.modifiers[l].cart_modifier_name,
              modifiers_values: modifierVal,
            });
          }
        } else if (item.cart_item_type === "Component" && ComboLen > 0) {
          for (var m = 0; m < ComboLen; m++) {
            var combomodifierdata = [];
            /* build  modifier array */
            var comboModifier =
              item.set_menu_component[m].product_details[0].modifiers;

            var combomodifierdata = [];
            if (comboModifier.length > 0) {
              for (var p = 0; p < comboModifier.length; p++) {
                var comboModifierVal = [];
                comboModifierVal.push({
                  modifier_value_id:
                    comboModifier[p].modifiers_values[0].cart_modifier_id,
                  modifier_value_qty:
                    comboModifier[p].modifiers_values[0].cart_modifier_qty,
                  modifier_value_name:
                    comboModifier[p].modifiers_values[0].cart_modifier_name,
                  modifier_value_price:
                    comboModifier[p].modifiers_values[0].cart_modifier_price,
                });
                combomodifierdata.push({
                  modifier_id: comboModifier[p].cart_modifier_id,
                  modifier_name: comboModifier[p].cart_modifier_name,
                  modifiers_values: comboModifierVal,
                });
              }
            }

            var comborVal = [];
            var comboProductDetailslen =
              item.set_menu_component[m].product_details.length;
            if (comboProductDetailslen > 0) {
              for (
                var j = 0, lengthComboPro = comboProductDetailslen;
                j < lengthComboPro;
                j++
              ) {
                comborVal.push({
                  product_id:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_id,
                  product_name:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_name,
                  product_sku:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_sku,
                  product_qty:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_qty,
                  product_price:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_price,
                  modifiers: combomodifierdata,
                });
              }
            }

            comboData.push({
              menu_component_id: item.set_menu_component[m].menu_component_id,
              menu_component_name:
                item.set_menu_component[m].menu_component_name,
              product_details: comborVal,
            });
          }
        }
        products.push({
          product_name: item.cart_item_product_name,
          product_unit_price: item.cart_item_unit_price,
          product_total_amount: item.cart_item_total_price,
          product_sku: item.cart_item_product_sku,
          product_image: item.cart_item_product_image,
          product_id: item.cart_item_product_id,
          product_qty: item.cart_item_qty,
          product_item_id: item.cart_item_id,
          condiments: "",
          modifiers: modifierdata,
          bakers_modifiers: "",
          menu_set_components: comboData,
          baby_pack: "",
          product_special_notes: item.cart_item_special_notes,
          voucher_gift_name: item.cart_item_product_voucher_gift_name,
          voucher_gift_email: item.cart_item_product_voucher_gift_email,
          voucher_gift_message: item.cart_item_product_voucher_gift_message,
          order_item_id:
            item.cart_voucher_order_item_id != "0"
              ? item.cart_voucher_order_item_id
              : "",
          voucher_free_product: item.cart_item_voucher_product_free,
          order_voucher_id: item.cart_item_voucher_id,
        });

        return products;
      }.bind(this)
    );

    if (paymentMode === "initial") {
      paymentMode = 2;
    }

    var promotionApplied = this.state.promotion_applied;
    var promotionAmount = this.state.promotion_amount;
    var promoIsDelivery = this.state.promoIs_delivery;

    var cartDetailsArr = this.state.cartDetails;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = promotionApplied;
    promoTionArr["promotionAmount"] = promotionAmount;
    promoTionArr["promoIsDelivery"] = promoIsDelivery;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      cartDetailsArr,
      this.props.cartItems,
      promoTionArr
    );

    var postObject = {};
    postObject = {
      /* cart details */
      allow_order: "No",
      app_id: appId,
      availability_id: dineinId,
      availability_name: "Dinein",
      customer_detail_skipped: "yes",
      customer_id: "",
      discount_applied: "No",
      ewallet_amount: "",
      grand_total: parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2),
      order_discount_both: 0,
      order_is_advanced: "No",
      order_no_of_pax: "1",
      order_remarks: "",
      order_service_charge: "0.00",
      order_service_charge_amount: "0.00",
      order_source: "Mobile",
      order_status: 1,
      order_tax_calculate_amount: parseFloat(
        calculatedAmount["orderGstAmount"]
      ).toFixed(2),
      outlet_id: cookie.load("orderOutletId"),
      payment_mode: paymentMode /*paymentMode, */,
      products: JSON.stringify(products),
      redeem_applied: "No",
      reward_point_status: "Yes",
      sub_total: parseFloat(calculatedAmount["cartSubTotalAmount"]).toFixed(2),
      table_number: cookie.load("table_number"),
      tax_charge: calculatedAmount["orderDisplayGst"],
      zero_processing: "No",
      order_data_encode: "Yes",
      order_qr_id: this.state.qr_id,
    };
    /* check  validation */
    if (validation === "Yes") {
      postObject["validation_only"] = "Yes";
    }
    if (confirmOrder === "yes") {
      postObject["order_primary_id"] = this.state.order_primary_id;
      axios
        .post(
          apiUrl + "ordersv1/confirm_order",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({ orderValidFail: 0 });

            if (validation !== "Yes") {
              var localOrderNo = res.data.common.local_order_no;
              var timeID = res.data.common.timeID;
              this.setState({ placingorderimage: tickImage });

              /* capture payemnt */
              this.showSuccessPage(localOrderNo, timeID);
            }
          } else if (res.data.status === "error") {
            // This is used for stripe Auth Capture
            this.setState({ orderValidFail: 1 });

            if (res.data.form_error !== undefined) {
              showAlert("Error", res.data.form_error);
            } else {
              showAlert("Error", res.data.message);
            }
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        })
        .catch((error) => {
          $.magnificPopup.close();
          this.paymentFail(
            "Error",
            "Error code: 1004 Oops! Unable to processing your order. Please try again."
          );
          $.magnificPopup.open({
            items: {
              src: ".warining_popup",
            },
            type: "inline",
          });
          return false;
        });
    } else {
      axios
        .post(
          apiUrl + "ordersv1/submit_order",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({ orderValidFail: 0 });

            if (validation !== "Yes") {
              var localOrderNo = res.data.common.local_order_no;
              var timeID = res.data.common.timeID;
              this.setState({ placingorderimage: tickImage });

              /* capture payemnt */
              this.showSuccessPage(localOrderNo, timeID);
            }
          } else if (res.data.status === "error") {
            // This is used for stripe Auth Capture
            this.setState({ orderValidFail: 1 });

            if (res.data.form_error !== undefined) {
              showAlert("Error", res.data.form_error);
            } else {
              showAlert("Error", res.data.message);
            }
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        })
        .catch((error) => {
          $.magnificPopup.close();
          this.paymentFail(
            "Error",
            "Error code: 1004 Oops! Unable to processing your order. Please try again."
          );
          $.magnificPopup.open({
            items: {
              src: ".warining_popup",
            },
            type: "inline",
          });
          return false;
        });
    }
  }

  pageReload = () => {
    setTimeout(function () {
      // window.location.reload();
    }, 15000);
  };

  paymentFail(header, message) {
    $(".warining_popup").remove();
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec warining_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:void(0);" onClick="' +
        this.pageReload() +
        '"class="btn btn-sm btn_yellow popup-modal-dismiss">OK</a></div></div></div></div></div></div>'
    );
  }

  /* sucess page */
  showSuccessPage(localOrderNo, timeID) {
    this.props.destroyCartDetail();
    cookie.save("ChkOrderid", localOrderNo, cookieConfig);
    const { history } = this.props;
    setTimeout(function () {
      $.magnificPopup.close();
      history.push("/view-bill/" + localOrderNo + "/" + timeID);
    }, 450);
  }
  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();

    cookie.remove("orderPaymentMode", cookieConfig);
    cookie.remove("orderTableNo", cookieConfig);
    cookie.remove("product_remarks", cookieConfig);
    cookie.remove("orderOutletName", cookieConfig);
    //cookie.remove('orderOutletId');
    cookie.remove("carttotalitems", cookieConfig);
    cookie.remove("cartsubtotal", cookieConfig);
    cookie.remove("cartid", cookieConfig);

    /* Delivery avilablity */
    cookie.remove("deliveryDate", cookieConfig);
    cookie.remove("deliveryTime", cookieConfig);
    cookie.remove("unitNoOne", cookieConfig);
    cookie.remove("unitNoTwo", cookieConfig);
    cookie.remove("firstNavigation", cookieConfig);

    cookie.remove("promotion_id", cookieConfig);
    cookie.remove("promotion_applied", cookieConfig);
    cookie.remove("promotion_code", cookieConfig);
    cookie.remove("promotion_delivery_charge_applied", cookieConfig);
    cookie.remove("promotion_amount", cookieConfig);
    cookie.remove("promotion_category", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);

    /*Remove voucher*/
    cookie.remove("voucher_applied", cookieConfig);
    cookie.remove("voucher_code", cookieConfig);
    cookie.remove("voucher_amount", cookieConfig);

    cookie.remove("points_redeemed", cookieConfig);
    cookie.remove("points_used", cookieConfig);
    cookie.remove("points_amount", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  render() {
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      this.props.cartItems,
      promoTionArr
    );
    var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);
    return (
      <div className="checkout-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Checkout</p>
          </div>
        </div>

        {/* container - start */}
        <div className="bo-container">
          <div className="container">
            {/* Birthday Info div - start */}
            <div className="birthday-info-div">
              <div className="birthday-info-top">
                <h3>
                  <div>
                    <span>Welcome</span>
                    <br />
                    User
                  </div>{" "}
                </h3>
              </div>
            </div>

            {/* Birthday Info div - end */}

            {/* check_out_fourcommon - start */}
            <div className="check_out_fourcommon">
              <div className="container-one">
                {/* checkoutpage_form_outer - start */}
                <div className="checkoutpage_form_outer">
                  <form
                    id="checkoutpage_form"
                    className=" form_sec checkout-total"
                    method="post"
                    acceptCharset="utf-8"
                  >
                    <div className="cdd-details">
                      {/* cdd-details-lhs - start */}
                      <div className="cdd-details-lhs fl">
                        <div className="text-center checkout-heading">
                          <span className="text-uppercase">Dinein Details</span>
                        </div>

                        <div className="checkout-body-section">
                          <div className="checkout-control-group-top">
                            <label className="chk_hea">Dinein Location</label>
                            <div className="col-xs-cls">
                              <p>
                                <b>{cookie.load("orderOutletName")}</b>
                              </p>
                              <p>{cookie.load("orderHandledByText")}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* cdd-details-lhs - end */}

                      {/* cdd-details-rhs - start */}
                      <div className="cdd-details-rhs fl">
                        <div className="chekout_cart_bar">
                          <div className="cart-header">
                            <div className="text-center checkout-heading">
                              <span className="text-uppercase">
                                Your Order Details
                              </span>
                            </div>
                          </div>

                          {this.loadCartOverallData()}
                        </div>
                      </div>
                      {/* cdd-details-rhs - end */}
                    </div>
                  </form>
                </div>
                {/* checkoutpage_form_outer - end */}
                {/* chk-payment div - start */}
                <div className="chk-payment chk-payment-main">
                  <div className="chk-payment-btn">
                    {parseFloat(payAmount) > 0 && (
                      <div className="chk-payment-btn-row">
                        <a
                          href="/"
                          className="button"
                          title="Continue"
                          onClick={this.payCash.bind(this)}
                        >
                          Confirm Order
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                {/* chk-payment div - end */}
              </div>
            </div>
            {/* check_out_fourcommon - end */}
          </div>
        </div>
        {/* container - end */}

        <div
          id="processing-popup"
          className="white-popup mfp-hide popup_sec processing"
        >
          <div className="pouup_in">
            <h3 className="title1 text-center">We Are Processing Your Order</h3>
            <div className="process_inner">
              {this.onlinePaymentLoading()}
              {this.orderBCLoading()}
              {this.amountCaptureLoading()}
            </div>
          </div>
        </div>

        {/* Warning Popup - start */}
        <div
          id="warning-delete-popup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">Warning</div>
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningImg} />
                  <p>Are you sure want to delete all item ?</p>
                  <div className="alt_btns">
                    <a
                      href="/"
                      className="popup-modal-dismiss button button-left disbl_href_action"
                    >
                      No
                    </a>
                    <a
                      href="/"
                      onClick={this.clearCartItm.bind(this)}
                      className="button button-right popup-modal-dismiss disbl_href_action"
                    >
                      Yes
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Warning Popup - end */}

        <div id="dvLoading"></div>

        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var updateCartResponse = Array();
  var cartTotalItmCount = 0;
  var cartStatus = "";

  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }

  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  if (Object.keys(state.updatecartdetail).length > 0) {
    if (state.updatecartdetail[0].status === "error") {
      updateCartResponse = state.updatecartdetail;
    }
  }

  return {
    settingsArr: globalSettings,
    zonedetails: zonedetailArr,
    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartTotalItmCount: cartTotalItmCount,
    cartStatus: cartStatus,
    updateCartResponse: updateCartResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
    updateCartDetail: (productId, cartItemId, cartQty, orderVoucherId) => {
      dispatch({
        type: UPDATE_CART_DETAIL,
        productId,
        cartItemId,
        cartQty,
        orderVoucherId,
      });
    },
    deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Confirmorder);
