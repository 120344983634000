/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { validated } from "react-custom-validation";
import Select from "react-select";
import country from "../../common/js/country.json";
var phoneCodeList = [];
country.forEach(function (element) {
  phoneCodeList.push({
    label: element.short_code + " | " + element.dial_code,
    value: element.dial_code,
  });
});

const isEmpty = (value) => (value === "" ? "This field is required." : null);

function validationConfigSignin(props) {
  const { mobile, password } = props.fields;

  return {
    fields: ["mobile", "password"],
    validations: {
      mobile: [
        [isEmpty, mobile],
        [isMobile, mobile],
      ],
      password: [[isEmpty, password]],
    },
  };
}

const phonenumberPattern = /^[0-9]{6,14}$/;
const isMobile = (mobile) =>
  mobile.match(phonenumberPattern) ? null : "please enter valid Mobile Number.";

class Membersignin extends Component {
  constructor(props) {
    super(props);
    this.state = { testlgn: "" };
  }

  closepopup(path) {
    const { history } = this.props;
    history.push(path);
    $.magnificPopup.close();
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgMobile, errMsgPassword;

    if (
      $validation.mobile.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }

    if (
      $validation.password.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgPassword = $validation.password.show && (
        <span className="error">{$validation.password.error.reason}</span>
      );
    }

    return (
      <div className="popup-body memberlogin-popup-body">
        <div className="form-group">
          <div
            className={fields.mobile !== "" ? "focus-out focused" : "focus-out"}
          >
            <div className="seperate-mob">
              <Select
                placeholder="Code"
                onChange={onChange.bind(this, "mobilecountry")}
                defaultValue={[
                  {
                    label: "SG | +65",
                    value: "+65",
                  },
                ]}
                menuIsOpen={false}
                isDisabled={true}
                options={phoneCodeList}
              />
              <input
                type="tel"
                placeholder="Mobile Number"
                className="form-control input-focus"
                pattern="\d*"
                value={fields.mobile}
                {...$field("mobile", (e) => onChange("mobile", e.target.value))}
              />
            </div>
            <div id="spn-mobile-error">{errMsgMobile}</div>
          </div>
        </div>

        <div className="form-group">
          <div
            className={
              fields.password !== "" ? "focus-out focused" : "focus-out"
            }
          >
            <input
              type="password"
              placeholder="Password"
              autoComplete="off"
              className="form-control input-focus"
              value={fields.password}
              {...$field("password", (e) =>
                onChange("password", e.target.value)
              )}
            />
            {errMsgPassword}
          </div>
        </div>
        <div className="form-group">
          <div className="controls two-links">
            <a
              href="#forgot-membershippassword-popup"
              className="open-popup-link"
            >
              Forgot Password
            </a>{" "}
          </div>
        </div>

        <div className="form-group">
          <div className="login_pop_sub">
            <button
              type="button"
              className="btn btn_black btn_minwid signup_submit member_signinsubmit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              Submit
            </button>
          </div>
        </div>

        <div className="form-group-11">
          <div className="controls single-link">
            <a href="#login-popup" className="open-popup-link">
              Back to login
            </a>
          </div>
        </div>
      </div>
    );
  }
}
Membersignin = validated(validationConfigSignin)(Membersignin);

export default withRouter(Membersignin);
