/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { appId, apiUrl, headerconfig } from "../Helpers/Config";
import { showLoader, hideLoader } from "../Helpers/SettingHelper";
import { validated } from "react-custom-validation";
import cookie from "react-cookies";
import axios from "axios";
import Select from "react-select";
import validator from "validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import country from "../../common/js/country.json";
var countryList = [];
var phoneCodeList = [];
country.forEach(function (element) {
  countryList.push({ label: element.name, value: element.name });
  phoneCodeList.push({
    label: element.short_code + " | " + element.dial_code,
    value: element.dial_code,
  });
});
const isEmpty = (value) => (value === "" ? "This field is required." : null);
const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";
const minLength = (password, length) =>
  password.length >= length ? null : "Password must be at least 6 characters.";
const areSame = (password, rePassword) =>
  password === rePassword ? null : "Password do not match.";
const isChecked = (terms) =>
  terms ? null : "Please accept terms and conditions";
function validationConfigSignup(props) {
  const {
    firstname,
    email,
    password,
    rePassword,
    mobile,
    gender,
    birthdate,
    nationality,
    referrercode,
    terms,
  } = props.fields;

  return {
    fields: [
      "firstname",
      "email",
      "password",
      "rePassword",
      "mobile",
      "gender",
      "birthdate",
      "nationality",
      "terms",
    ],

    validations: {
      firstname: [[isEmpty, firstname]],
      email: [
        [isEmail, email],
        [isUnique, email],
      ],
      password: [[minLength, password, 6]],
      rePassword: {
        rules: [[areSame, password, rePassword]],
        fields: ["password", "rePassword"],
      },
      mobile: [
        [isEmpty, mobile],
        [isMobile, mobile],
        [isPhonenumerExist, mobile],
      ],
      gender: [[isEmpty, gender]],
      birthdate: [[isEmpty, birthdate]],
      nationality: [[isEmpty, nationality]],
      terms: [[isChecked, terms, 1]],
    },
  };
}

const phonenumberPattern = /^[0-9]{6,14}$/;
const isMobile = (mobile) =>
  mobile.match(phonenumberPattern) ? null : "please enter valid Mobile Number.";

const isUnique = (email) => {
  var customerId =
    typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
  if (customerId === "") {
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_email: email,
    };

    axios
      .post(
        apiUrl + "customer/email_exist",
        qs.stringify(postObject),
        headerconfig
      )
      .then((response) => {
        if (response.data.status === "ok") {
          document.getElementById("spn-email-error").innerHTML = "";
        } else {
          document.getElementById("spn-email-error").innerHTML =
            '<span class="error">Email already exists</span>';
        }
      });
  }
};

const isPhonenumerExistInMembr = (mobile) => {
	if(mobile !== '') {
		var qs = require("qs");
		var postObject = {
		  app_id: appId,
		  mobile_no: mobile,
		};
		axios
		  .post(
			apiUrl + "ascentiscrm/memberEnquiryByMbl",
			qs.stringify(postObject),
			headerconfig
		  )
		  .then((response) => {
			if (response.data.status === "ok") {
			  document.getElementById("spn-mobile-error").innerHTML = '<span class="error">Mobile number already exists</span>';
			  $(".memberlogup-popup-body").find(".mob-otp-sendandvalidate").attr("data-mblalreadyexit", "yes");
			  $(".memberlogup-popup-body").find(".mob-otp-sendand").hide();
			  $(".memberlogup-popup-body").find(".mob-otp-validate").hide();
			  $(".memberlogup-popup-body").find(".otpvalidatediv_row").hide();	
			} else {
			  document.getElementById("spn-mobile-error").innerHTML = "";
			  $(".memberlogup-popup-body").find(".mob-otp-sendandvalidate").attr("data-mblalreadyexit", "no");
			  $(".memberlogup-popup-body").find(".mob-otp-sendand").show();
			  $(".memberlogup-popup-body").find(".mob-otp-validate").hide();
			  $(".memberlogup-popup-body").find(".otpvalidatediv_row").hide();
			}
			console.log('DDWWSS',mobile);
		  });
	}
};

const isPhonenumerExist = (mobile) => {
  var customerId =
    typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
  if (customerId === "") {
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_phone: mobile,
    };
    axios
      .post(
        apiUrl + "customer/phoneno_exist",
        qs.stringify(postObject),
        headerconfig
      )
      .then((response) => {
        if (response.data.status === "ok") {
          document.getElementById("spn-mobile-error").innerHTML = "";
		  $(".memberlogup-popup-body").find(".mob-otp-sendandvalidate").attr("data-mblalreadyexit", "no");
		  $(".memberlogup-popup-body").find(".mob-otp-sendand").show();
		  $(".memberlogup-popup-body").find(".mob-otp-validate").hide();
          $(".memberlogup-popup-body").find(".otpvalidatediv_row").hide();
		  isPhonenumerExistInMembr(mobile);
        } else {
          document.getElementById("spn-mobile-error").innerHTML = '<span class="error">Mobile number already exists</span>';
		  $(".memberlogup-popup-body").find(".mob-otp-sendandvalidate").attr("data-mblalreadyexit", "yes");
		  $(".memberlogup-popup-body").find(".mob-otp-sendand").hide();
		  $(".memberlogup-popup-body").find(".mob-otp-validate").hide();
          $(".memberlogup-popup-body").find(".otpvalidatediv_row").hide();
        }
        var otpsend = $(".mob-otp-sendandvalidate").attr("data-otpsend");
        if (otpsend === "yes") {
          $(".memberlogup-popup-body")
            .find(".mob-otp-sendandvalidate")
            .attr("data-otpsend", "no");
          $(".memberlogup-popup-body")
            .find(".mob-otp-sendandvalidate")
            .attr("data-otpvalidate", "no");
          $(".memberlogup-popup-body")
            .find(".mob-otp-sendandvalidate")
            .attr("data-otpnumbertxt", "");
          $(".memberlogup-popup-body").find(".mob-otp-sendand").show();
          $(".memberlogup-popup-body").find(".mob-otp-validate").hide();
          $(".memberlogup-popup-body").find(".otpvalidatediv_row").hide();
        }
      });
  } else {
	  isPhonenumerExistInMembr(mobile);
  }
};

class Membersignup extends Component {
  constructor(props) {
    var Maxdate = new Date();
    Maxdate.setFullYear(Maxdate.getFullYear() - 10);
    super(props);
    this.state = {
      Maxdate: Maxdate,
      otpsend: "no",
      otp_number_txt: "",
      otp_validate: "no",
      otp_validate_mbl: "",
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }

  closepopup(path) {
    const { history } = this.props;
    history.push(path);
    $.magnificPopup.close();
  }

  getMemberOtp(mobile) {
    var mobileLngth = mobile !== "" ? mobile.length : 0;
    var otpsend = "no";
    var otp_number_txt = "";
    if (mobileLngth === 8) {
      showLoader("member_get_opt", "class");
      var qs = require("qs");
      var postObject = { app_id: appId, member_phone: mobile };
      axios
        .post(
          apiUrl + "ascentiscrm/memberSendOTP",
          qs.stringify(postObject),
          headerconfig
        )
        .then((response) => {
          hideLoader("member_get_opt", "class");
          if (response.data.status === "ok") {
            otp_number_txt =
              Object.keys(response.data.result_set).length > 0
                ? response.data.result_set.OTPcode
                : "";
            otpsend = "yes";
            document.getElementById("spn-mobile-error").innerHTML = "";
            $(".memberlogup-popup-body").find(".mob-otp-sendand").hide();
            $(".memberlogup-popup-body").find(".mob-otp-validate").hide();
            $(".memberlogup-popup-body").find(".otpvalidatediv_row").show();
          } else {
            document.getElementById("spn-mobile-error").innerHTML =
              '<span class="error">Did not send otp this number. try agin.</span>';
            $(".memberlogup-popup-body").find(".mob-otp-sendand").show();
            $(".memberlogup-popup-body").find(".mob-otp-validate").hide();
            $(".memberlogup-popup-body").find(".otpvalidatediv_row").hide();
          }
          /*this.setState({ otpsend: otpsend, otp_number_txt: otp_number_txt });*/
          $(".memberlogup-popup-body")
            .find(".mob-otp-sendandvalidate")
            .attr("data-otpsend", otpsend);
          $(".memberlogup-popup-body")
            .find(".mob-otp-sendandvalidate")
            .attr("data-otpnumbertxt", otp_number_txt);
        });
    } else {
      document.getElementById("spn-mobile-error").innerHTML =
        '<span class="error">Mobile number is invalide.</span>';
      $(".memberlogup-popup-body").find(".mob-otp-sendand").show();
      $(".memberlogup-popup-body").find(".mob-otp-validate").hide();
      $(".memberlogup-popup-body").find(".otpvalidatediv_row").hide();
      /*this.setState({ otpsend: 'no', otp_number_txt: ''});*/
      $(".memberlogup-popup-body")
        .find(".mob-otp-sendandvalidate")
        .attr("data-otpsend", otpsend);
      $(".memberlogup-popup-body")
        .find(".mob-otp-sendandvalidate")
        .attr("data-otpnumbertxt", otp_number_txt);
    }
  }

  validateMemberOtp() {
    var otpsend = $(".memberlogup-popup-body")
      .find(".mob-otp-sendandvalidate")
      .attr("data-otpsend");
    var otpnumbertxt = $(".memberlogup-popup-body")
      .find(".mob-otp-sendandvalidate")
      .attr("data-otpnumbertxt");
    var otpnumberval = $(".memberlogup-popup-body")
      .find(".otpnumberval-input")
      .val();
    if (
      otpsend === "yes" &&
      otpnumbertxt !== "" &&
      otpnumberval !== "" &&
      otpnumberval === otpnumbertxt
    ) {
      $(".memberlogup-popup-body")
        .find(".mob-otp-sendandvalidate")
        .attr("data-otpvalidate", "yes");
      $(".memberlogup-popup-body").find(".mob-otp-sendand").hide();
      $(".memberlogup-popup-body").find(".mob-otp-validate").show();
      $(".memberlogup-popup-body").find(".otpvalidatediv_row").hide();
      document.getElementById("spn-mobile-error").innerHTML = "";
    } else {
      $(".memberlogup-popup-body").find(".mob-otp-validate").hide();
      $(".memberlogup-popup-body").find(".otpvalidatediv_row").show();
      $(".memberlogup-popup-body")
        .find(".mob-otp-sendandvalidate")
        .attr("data-otpvalidate", "no");
    }
  }

  handleChangeDate(datevalue) {
    var dateval = new Date(datevalue);
    dateval = (datevalue != '' && datevalue != null) ? format(dateval, "dd/MM/yyyy") : '';
    this.setState({ birthdate: datevalue }, function () { this.props.onChange("birthdate", dateval); }.bind(this));
  }
  
  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;

    let errMsgFirstName,
      errMsgEmail,
      errMsgPassword,
      errMsgrePassword,
      errMsgMobile,
      errMsgGender,
      errMsgBirthdate,
      errMsgNationality,
      errMsgTerms;

    if (
      $validation.firstname.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgFirstName = $validation.firstname.show && (
        <span className="error">{$validation.firstname.error.reason}</span>
      );
    }
    if (
      $validation.email.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }
    if (
      $validation.password.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgPassword = $validation.password.show && (
        <span className="error">{$validation.password.error.reason}</span>
      );
    }
    if (
      $validation.rePassword.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgrePassword = $validation.rePassword.show && (
        <span className="error">{$validation.rePassword.error.reason}</span>
      );
    }
    if (
      $validation.mobile.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }
    if (
      $validation.gender.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgGender = $validation.gender.show && (
        <span className="error">{$validation.gender.error.reason}</span>
      );
    }
    if (
      $validation.birthdate.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgBirthdate = $validation.birthdate.show && (
        <span className="error">{$validation.birthdate.error.reason}</span>
      );
    }
    if (
      $validation.nationality.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgNationality = $validation.nationality.show && (
        <span className="error">{$validation.nationality.error.reason}</span>
      );
    }
    if (
      $validation.terms.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgTerms = $validation.terms.show && (
        <span className="error">{$validation.terms.error.reason}</span>
      );
    }

    var birthdate = new Date();
    if (this.state.birthdate !== "") {
      birthdate = this.state.birthdate;
    } else if (
      fields.birthdate !== "" &&
      fields.birthdate !== "0000-00-00" &&
      fields.birthdate !== "00/00/0000"
    ) {
      birthdate = new Date(fields.birthdate);
    }

    return (
      <div className="popup-body memberlogup-popup-body">
        <div className="form-group">
          <div
            className={
              fields.firstname !== "" ? "focus-out focused" : "focus-out"
            }
          >
            <input
              type="text"
              placeholder="Name"
              autoComplete="off"
              className="form-control input-focus"
              value={fields.firstname}
              {...$field("firstname", (e) =>
                onChange("firstname", e.target.value)
              )}
            />
            {errMsgFirstName}
          </div>
        </div>
        <div className="form-group">
          <div
            className={
              fields.password !== "" ? "focus-out focused" : "focus-out"
            }
          >
            <input
              type="password"
              placeholder="Password"
              autoComplete="off"
              className="form-control input-focus"
              value={fields.password}
              {...$field("password", (e) =>
                onChange("password", e.target.value)
              )}
            />
            {errMsgPassword}
          </div>
        </div>
        <div className="form-group">
          <div
            className={
              fields.rePassword !== "" ? "focus-out focused" : "focus-out"
            }
          >
            <input
              type="password"
              placeholder="Confirm Password"
              autoComplete="off"
              className="form-control input-focus"
              value={fields.rePassword}
              {...$field("rePassword", (e) =>
                onChange("rePassword", e.target.value)
              )}
            />
            {errMsgrePassword}
          </div>
        </div>
        <div className="form-group">
          <div
            className={fields.email !== "" ? "focus-out focused" : "focus-out"}
          >
            <input
              type="text"
              placeholder="Email"
              autoComplete="off"
              className="form-control input-focus"
              value={fields.email}
              {...$field("email", (e) => onChange("email", e.target.value))}
            />
            <div id="spn-email-error">{errMsgEmail}</div>
          </div>
        </div>

        <div className="member-updates-msg">
          Important membership updates, member promotions and account
          information will be sent via email.
        </div>

        <div className="form-group">
          <div className="re_select">
            {
              /* fields.gender !== "" && ( */
              <Select
                placeholder="Gender"
                onChange={onChange.bind(this, "gender")}
                options={[
                  { value: "M", label: "Male" },
                  { value: "F", label: "Female" },
                  {
                    value: "O",
                    label: "Unspecified",
                  },
                ]}
              />
              /* ) */
            }
          </div>
        </div>
        <div className="form-group">
          <div
            className={fields.gender != "" ? "focus-out focused" : "focus-out"}
          >
            {/*  <label>Birthday</label> */}
            <div className="react_datepicker">
              <DatePicker
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Birthday"
                className="form-control"
                selected={birthdate}
                maxDate={this.state.Maxdate}
                dateFormat="dd/MM/yyyy"
                onChange={this.handleChangeDate}
              />
            </div>
          </div>
          <div id="spn-birthdate-error">{errMsgBirthdate}</div>
        </div>
        <div className="form-group">
          <div
            className={fields.mobile !== "" ? "focus-out focused" : "focus-out"}
          >
            <div className="seperate-mob-main">
              <div className="seperate-mob">
                <Select
                  placeholder="Code"
                  onChange={onChange.bind(this, "mobilecountry")}
                  defaultValue={[
                    {
                      label: "SG | +65",
                      value: "+65",
                    },
                  ]}
                  menuIsOpen={false}
                  isDisabled={true}
                  options={phoneCodeList}
                />
                <input
                  type="tel"
                  placeholder="Mobile Number"
                  className="form-control input-focus"
                  pattern="\d*"
                  value={fields.mobile}
                  {...$field("mobile", (e) =>
                    onChange("mobile", e.target.value)
                  )}
                />
              </div>
              <div
                className="mob-otp-sendandvalidate"
                data-otpsend="no"
                data-otpvalidate="no"
                data-otpnumbertxt=""
				data-mblalreadyexit="no"
              >
                <div className="mob-otp-sendand">
                  <button
                    type="button"
                    className="btn btn_black btn_minwid member_get_opt"
                    onClick={this.getMemberOtp.bind(this, fields.mobile)}
                  >
                    Get OTP
                  </button>
                </div>
                <div className="mob-otp-validate" style={{ display: "none" }}>
                  <button
                    type="button"
                    className="btn btn_black btn_minwid member_vldt_opt"
                  >
                    Validated
                  </button>
                </div>
              </div>
            </div>
            <div id="spn-mobile-error">{errMsgMobile}</div>
          </div>
        </div>
        <div
          className="form-group otpvalidatediv_row"
          data-otpnumberval=""
          style={{ display: "none" }}
        >
          <div className="focus-out focused">
            <input
              type="text"
              placeholder="OTP"
              autoComplete="off"
              className="form-control input-focus otpnumberval-input"
            />
            <button
              type="button"
              className="btn btn_black btn_minwid member_opt_vld"
              onClick={this.validateMemberOtp.bind(this)}
            >
              Validate
            </button>
            <div id="spn-otp-error">{errMsgEmail}</div>
          </div>
        </div>

        <div className="form-group">
          <div
            className={
              fields.firstname !== "" ? "focus-out focused" : "focus-out"
            }
          >
            <div className="re_select">
              <Select
                placeholder="Nationality"
                onChange={onChange.bind(this, "nationality")}
                options={countryList}
              />
            </div>
            {errMsgNationality}
          </div>
        </div>

        <div className="form-group">
          <div
            className={
              fields.referrercode !== "" ? "focus-out focused" : "focus-out"
            }
          >
            <input
              type="text"
              placeholder="Referrer Code"
              autoComplete="off"
              className="form-control input-focus"
              value={fields.referrercode}
              {...$field("referrercode", (e) =>
                onChange("referrercode", e.target.value)
              )}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="custom_checkbox">
            <input
              type="checkbox"
              id="memberterms"
              checked={fields.terms}
              {...$field(
                "terms",
                (e) => onChange("terms", e.target.value),
                null,
                false
              )}
            />{" "}
            <span></span>
            <div className="sign_reg">
              <p>
                I agree to the{" "}
                <a
                  className="sign_reg_uline"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      "https://sushitei.com/terms-and-conditions/",
                      "_blank"
                    );
                  }}
                  href="https://sushitei.com/terms-and-conditions/"
                >
                  terms & conditions
                </a>
              </p>
            </div>
          </div>
          {errMsgTerms}
        </div>
        <div className="form-group">
          <div className="custom_checkbox custom_checkbox_content">
            <input
              type="checkbox"
              id="memberprivacy"
              checked={fields.memberprivacy}
              {...$field(
                "memberprivacy",
                (e) => onChange("memberprivacy", e.target.value),
                null,
                false
              )}
            />{" "}
            <span></span>
            <div className="sign_reg">
              <p>
                By submitting this form, I hereby give my consent to Sushi Tei
                Ptd Ltd to collect, use and disclose my personal data for the
                purposes of processing my membership application, administering
                my membership, as well as notifying and contacting me regarding
                membership, sending me marketing, advertising and promotional
                information about products/services that Sushi Tei Pte Ltd and
                its affiliates may be offering, via calls, text messages, post
                and emails. I am aware that I may update the personal data
                and/or withdraw the consent provided by me at any time by
                contacting dpo@sushitei.com. The Sushi Tei Pte Ltd Privacy
                Policy and how my personal data will be used is also available{" "}
                <a
                  className="sign_reg_uline"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      "https://sushitei.com/privacy-policy/",
                      "_blank"
                    );
                  }}
                  href="https://sushitei.com/privacy-policy/"
                >
                  here
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="login_pop_sub">
            <button
              type="button"
              className="btn btn_black btn_minwid signup_submit member_signup_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              Submit
            </button>
          </div>
        </div>

        <div className="form-group-11">
          <div className="controls single-link">
            <a href="#login-popup" className="open-popup-link">
              Back to login
            </a>
          </div>
        </div>
      </div>
    );
  }
}
Membersignup = validated(validationConfigSignup)(Membersignup);

export default withRouter(Membersignup);
