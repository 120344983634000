/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { format } from "date-fns";
import {
  stripslashes,
  addressFormat,
  showPrices,
} from "../Helpers/SettingHelper";
import thnkyou_tick_img from "../../common/images/like1.png";
import { GET_ORDER_DETAIL, DESTROY_CART_DETAIL } from "../../actions";
var Parser = require("html-react-parser");
class Viewbill extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    let orderId =
      typeof this.props.match.params.orderId !== "undefined"
        ? this.props.match.params.orderId
        : "";
    let timeID =
      typeof this.props.match.params.timeID !== "undefined"
        ? this.props.match.params.timeID
        : "";
    var chkOrderid =
      typeof cookie.load("ChkOrderid") === "undefined"
        ? ""
        : cookie.load("ChkOrderid");
    /*  && chkOrderid === orderId */
    if (orderId !== "") {
      this.props.getOrderDetail(orderId, timeID);
      this.props.destroyCartDetail();
    } else {
      this.props.history.push("/myorders");
    }
  }

  loadItems(orderDetail) {
    if (Object.keys(orderDetail).length > 0) {
      var cartItems = orderDetail["items"];
      return cartItems.map((item, index) => (
        <div key={index}>
          <div className="cart_row product-details">
            <div className="row-replace">
              <div className="col-sm-cls cart_left">
                <div className="cart_info">
                  <h4>
                    {stripslashes(item.item_name)} X {item.item_qty}
                  </h4>

                  {this.loadModifierItems(
                    "Component",
                    item.modifiers,
                    item.set_menu_component
                  )}

                  {item.item_specification !== "" && (
                    <p className="help-block">
                      {stripslashes(item.item_specification)}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-sm-cls cart_right text-right">
                <div className="cart_price">
                  <p>
                    {showPrices(parseFloat(item.item_total_amount).toFixed(2))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    } else {
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p><b>" +
          stripslashes(modName) +
          ": </b> " +
          stripslashes(modval) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        html +=
          "<p><b>" +
          comboName +
          ":</b></p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
        var comboQtyChk = comboQty !== "" ? parseInt(comboQty) : 0;
        if (comboQtyChk > 0) {
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b></p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }

  render() {
    let orderArr = this.props.orderdetail;
    var orderDetail = [];

    if (Object.keys(orderArr).length > 0) {
      if (orderArr[0].status === "ok") {
        orderDetail = orderArr[0].result_set[0];
      }
    }

    return (
      <div className="thankyou-main-div">
        {/* Header start */}
        <Header />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          {/*<div className="common-inner-banner"><p>Thank You</p></div>*/}
        </div>

        {/* container - start */}
        <div className="container">
          {/* innersection_wrap - start */}
          {Object.keys(orderDetail).length > 0 ? (
            <div className="innersection_wrap tnk-you">
              <div className="mainacc_toptext tick">
                <img src={thnkyou_tick_img} />
                <h2>Thank You</h2>
                <p>Your order has been send successfully</p>
              </div>

              {/* order-detail-maindiv - start */}
              <div className="thank-order-detaildiv">
                <div className="tnk-detail text-center">
                  <h2>YOUR ORDER DETAILS</h2>
                  <div className="tnk-order">
                    <p>
                      SUSHI TEI
                      <br />
                      Order placed at :{" "}
                      {Moment(orderDetail.order_created_on).format(
                        "DD/MM/YYYY hh:mm A"
                      )}
                    </p>
                  </div>
                </div>

                <div className="tnk-delivery">
                  <div className="pickup-cart-div">
                    <div className="cart-direction"></div>
                    <div className="cart_row cart-header-first">
                      <div className="pickup-thankfull text-center">
                        <h4>Dinein Location</h4>
                        <p>{stripslashes(orderDetail.outlet_name)}</p>
                        <p>
                          {addressFormat(
                            orderDetail.outlet_unit_number1,
                            orderDetail.outlet_unit_number2,
                            orderDetail.outlet_address_line1,
                            orderDetail.outlet_address_line2,
                            orderDetail.outlet_postal_code
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="order-details-with-clear">
                    <h5>Your Items</h5>
                  </div>
                  <div className="orderitem_body_div">
                    {this.loadItems(orderDetail)}
                    {orderDetail.order_remarks !== "" && (
                      <div className="remark_notesec text-left">
                        <h4>Remarks</h4>
                        <p>{orderDetail.order_remarks}</p>
                      </div>
                    )}
                    {orderDetail.order_customer_send_gift === "Yes" && (
                      <>
                        <div className="remark_notesec text-left">
                          <h4>Recipient Name</h4>
                          <p>{orderDetail.order_recipient_name}</p>
                        </div>
                        <div className="remark_notesec text-left">
                          <h4>Recipient Contact Number</h4>
                          <p>{orderDetail.order_recipient_contact_no}</p>
                        </div>
                        {orderDetail.order_gift_message !== "" &&
                          orderDetail.order_gift_message !== null && (
                            <div className="remark_notesec text-left">
                              <h4>Gift Message</h4>
                              <p>{orderDetail.order_gift_message}</p>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* order-detail-maindiv - end */}
            </div>
          ) : (
            <div id="dvLoading"></div>
          )}
          {/* innersection_wrap - start */}
        </div>
        {/* container - end */}

        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    orderdetail: state.orderdetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderDetail: (orderId, timeID) => {
      dispatch({ type: GET_ORDER_DETAIL, orderId, timeID });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Viewbill);
