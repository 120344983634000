import React, { Component } from "react";
import cookie from "react-cookies";
import { cookieConfig } from "../Helpers/Config";

class Logout extends Component {
  componentWillMount() {
    cookie.remove("UserId", cookieConfig);
    cookie.remove("access_token", cookieConfig);
    cookie.remove("UserFname", cookieConfig);
    cookie.remove("UserLname", cookieConfig);
    cookie.remove("UserMobile", cookieConfig);
    cookie.remove("UserEmail", cookieConfig);
    cookie.remove("AscentisMemberPayment", cookieConfig);
    cookie.remove("AscentisMemberPhone", cookieConfig);
    cookie.remove("AcentisMemberid", cookieConfig);
    cookie.remove("AscentisMemberCreatedfrom", cookieConfig);
    cookie.remove("AscentisMemberPassword", cookieConfig);

    cookie.remove("orderPaymentMode", cookieConfig);
    cookie.remove("orderOutletId", cookieConfig);
    cookie.remove("orderTableNo", cookieConfig);
    cookie.remove("product_remarks", cookieConfig);
    cookie.remove("orderOutletName", cookieConfig);
    cookie.remove("orderZoneId", cookieConfig);
    cookie.remove("carttotalitems", cookieConfig);
    cookie.remove("cartsubtotal", cookieConfig);
    cookie.remove("cartid", cookieConfig);

    /* Delivery avilablity */
    cookie.remove("DeliveryDate", cookieConfig);
    cookie.remove("DeliveryTime", cookieConfig);
    cookie.remove("unitNoOne", cookieConfig);
    cookie.remove("unitNoTwo", cookieConfig);

    cookie.remove("promotion_id", cookieConfig);
    cookie.remove("promotion_applied", cookieConfig);
    cookie.remove("promotion_code", cookieConfig);
    cookie.remove("promotion_delivery_charge_applied", cookieConfig);
    cookie.remove("promotion_amount", cookieConfig);
    cookie.remove("promotion_category", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);

    /*Remove voucher*/
    cookie.remove("voucher_applied", cookieConfig);
    cookie.remove("voucher_code", cookieConfig);
    cookie.remove("voucher_amount", cookieConfig);

    cookie.remove("orderIdTxt", cookieConfig);
    cookie.remove("paymentIdTxt", cookieConfig);
    sessionStorage.removeItem("mytime");
    this.props.history.push("/");
  }

  render() {
    return <h1 className="loading-text">Logging out...</h1>;
  }
}

export default Logout;
