/* Live */
import cookie from "react-cookies";
export const appId = "48F27C1E-A55A-4DCE-89EC-674F1DA5C960";
export const projectTitle = "Sushi Tei";
var api_url = "https://ceres.ninjaos.com/";
//var api_url = "https://ceres.promobuddy.asia/";
if (
  cookie.load("api_url") !== "" &&
  typeof cookie.load("api_url") !== undefined &&
  typeof cookie.load("api_url") !== "undefined"
) {
  api_url = cookie.load("api_url");
}

export const apiUrl = api_url + "api/";
export const apiUrlV2 = api_url + "apiv2/";
export const apiUrlPro = api_url + "ninjapro/";
export const apiUrlNotify = api_url + "Pushorder/";
export const timThumpUrl = api_url + "timthumb.php?src=";
export const mediaUrl = api_url + "media/dev_team/";
export const blogImageUrl = api_url + "media/dev_team/blog/";
export const tagImageUrl = api_url + "media/dev_team/tag/";
export const proFolderUrl = api_url + "media/dev_team/products/";
export const baseUrl = "https://order.sushitei.com/";

export const stripeImage = "/img/stripe_logo.png";

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const dineinId = "EF9FB350-4FD4-4894-9381-3E859AB74019";

export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "Sushi Tei";

export const fbAppId = "399521621484386";

export const stripeKey = "pk_test_mSKQhHZYbRvm1YvuX9mKN4zx";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "sushitei";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "SGD";
export const currencySymbol = "$";
export const defaultOutletID = 354;
export const cookieage = 7776000;

export const cookieConfig = {
  path: "/",
  /*  secure: true,
  httpOnly: false, */
};
var accesstoken = "";
if (
  cookie.load("access_token") !== "" &&
  typeof cookie.load("access_token") !== undefined &&
  typeof cookie.load("access_token") !== "undefined"
) {
  accesstoken = {
    "x-api-key": "W6W3SUXRTC7J5JUT6OYTWB14DBG718",
    Authorization: cookie.load("access_token"),
  };
} else {
  accesstoken = {
    "x-api-key": "W6W3SUXRTC7J5JUT6OYTWB14DBG718",
  };
}

export const headerconfig = {
  headers: accesstoken,
};
