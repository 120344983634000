/* eslint-disable */
if (location.protocol !== "https:") {
  var locationULR = location.href.replace("www.", "");
  location.href = locationULR.replace("http://", "https://");
}
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

/* import Home from "./components/Home/Home"; */
import Products from "./components/Products/Products";
import ProductDetail from "./components/Products/ProductDetail";
import FavouriteProducts from "./components/Products/FavouriteProducts";
import Checkout from "./components/Checkout/Checkout";
import Confirmorder from "./components/Checkout/Confirmorder";
import Thankyou from "./components/Checkout/Thankyou";
import Viewbill from "./components/Checkout/Viewbill";
import Process from "./components/Checkout/Process";
import Cancel from "./components/Checkout/Cancel";
import Pages from "./components/Pages/Pages";
import ContactUs from "./components/Pages/ContactUs";
import Outlets from "./components/Pages/Outlets";
import Faq from "./components/Pages/Faq";
import Myaccount from "./components/Myaccount/Myaccount";
import Orders from "./components/Myaccount/Orders";
import Mypromotions from "./components/Myaccount/Mypromotions";
import Myvoucher from "./components/Myaccount/Myvoucher";
import Rewards from "./components/Myaccount/Rewards";
import Rewardsfromcrm from "./components/Myaccount/Rewardsfromcrm";
import Account from "./components/Account/Account";
import Resetpassword from "./components/Account/Resetpassword";
import Resetmemberpassword from "./components/Account/Resetmemberpassword";
import Logout from "./components/Myaccount/Logout";
import Refpage from "./components/Layout/Refpage";
import Memberlogin from "./components/Layout/Memberlogin";
import Memberrenewal from "./components/Layout/Memberrenewal";
import Checkingqr from "./components/Account/Checkingqr";
import Page404 from "./Page404";

const store = getStore();

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Products} />
        <Route exact path="/sign-in" component={Products} />

        <Route
          path={"/menu/:slugType/:slugValue/:proValue"}
          component={ProductDetail}
        />
        <Route path={"/menu/:slugType/:slugValue"} component={Products} />
        <Route path="/menu" component={Products} />
        <Route path="/favourite" component={FavouriteProducts} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/thankyou/:orderId" component={Thankyou} />
        <Route path="/process" component={Process} />
        <Route path="/cancel/:referanceID" component={Cancel} />
        <Route path="/confirm-order" component={Confirmorder} />
        <Route path="/view-bill/:orderId/:timeID" component={Viewbill} />
        <Route path="/about-us" component={Pages} />
        <Route path="/our-brand" component={Pages} />
        <Route path="/career-with-us" component={Pages} />
        <Route path="/membership-and-rewards" component={Pages} />
        <Route path="/gallery" component={Pages} />
        <Route path="/our-team" component={Pages} />
        <Route path="/terms-conditions" component={Pages} />
        <Route path="/privacy-policy" component={Pages} />
        <Route path="/page/:page_slug" component={Pages} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/faq" component={Faq} />
        <Route path="/store-information" component={Outlets} />
        <Route path="/crmrewards" component={Rewardsfromcrm} />
        <Route path="/rewards" component={Rewards} />
        <Route path="/mypromotions" component={Mypromotions} />
        <Route path="/voucher" component={Myvoucher} />
        <Route path="/myorders" component={Orders} />
        <Route path="/myaccount" component={Myaccount} />
        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />
        <Route
          path="/account/resetmemberpassword/:resetKey"
          component={Resetmemberpassword}
        />
        <Route path="/dineinorder/:QRId" component={Checkingqr} />

        <Route path="/logout" component={Logout} />
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route path={"/refpage"} component={Refpage} />
        <Route
          path={"/memberlogin/:slugtext/:slugid"}
          component={Memberlogin}
        />
        <Route
          path={"/memberrenewal/:slugtext/:slugid"}
          component={Memberrenewal}
        />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
