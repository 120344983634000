/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_FAVOURITELIST, SET_FAVOURITELIST } from "../actions";
import { apiUrl, appId, headerconfig } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetFavouriteproductslist = function* () {
  yield takeEvery(GET_FAVOURITELIST, workerGetFavouriteproductslist);
};

function* workerGetFavouriteproductslist() {
  try {
    const uri =
      apiUrl +
      "products/favourite_products_list?app_id=" +
      appId +
      "&customer_id=" +
      cookie.load("UserId");
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_FAVOURITELIST, value: resultArr });
  } catch (e) {
    console.log(e, "Get Favourite Products List Failed");
  }
}
