/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import Slider from "react-slick";
import { GET_ACTIVITYCOUNT } from "../../actions";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class Myaccount extends Component {
  constructor(props) {
    super(props);
    var acentisMemberid =
      typeof cookie.load("AcentisMemberid") === "undefined"
        ? ""
        : cookie.load("AcentisMemberid");
    acentisMemberid =
      acentisMemberid !== "null" && acentisMemberid !== null
        ? acentisMemberid
        : "";
    this.state = {
      order_all: 0,
      overall_orders: 0,

      activityPoints: {
        reward_ponits: "",
        reward_ponits_monthly: "",
        order_all: "",
        promotion: "",
      },
      ordercount: 0,
    };
  }

  componentDidMount() {
    this.getActivityCountsNew();
  }

  getActivityCountsNew() {
    const inputKeys = ["order_all", "overall_orders"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  render() {
    var AcentisMemberid =
      typeof cookie.load("AcentisMemberid") === "undefined"
        ? ""
        : cookie.load("AcentisMemberid");
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };

    return (
      <div className="mainacc_menuout">
        <ul className="mainacc_menulist">
          <li
            className={this.props.currentPage === "myaccount" ? "active" : ""}
          >
            <Link to="/myaccount" title="My Account">
              <span>Account Details</span>
            </Link>
          </li>
          <li className={this.props.currentPage === "myorders" ? "active" : ""}>
            <Link to="/myorders" title="My Orders">
              <span>Orders</span>
              {parseFloat(this.state.overall_orders) > 0 && (
                <span id="masterCount">{this.state.overall_orders}</span>
              )}
            </Link>
          </li>
          <li
            className={this.props.currentPage === "crmrewards" ? "active" : ""}
          >
            <Link to="/crmrewards" title="My Rewards">
              <span>Rewards</span>
            </Link>
          </li>
          <li
            className={
              this.props.currentPage === "mypromotions" ? "active" : ""
            }
          >
            <Link to="/mypromotions" title="My Promotions">
              <span>Promotions</span>
            </Link>
          </li>
          <li className={this.props.currentPage === "voucher" ? "active" : ""}>
            <Link to="/voucher" title="Voucher">
              <span>Voucher</span>
            </Link>
          </li>
          {AcentisMemberid !== "" ? (
            <li>
              <a
                href="#change-memberpassword-popup"
                className="open-popup-link"
              >
                <span>Change Member Password</span>
              </a>
            </li>
          ) : (
            <li>
              <a href="#change-password-popup" className="open-popup-link">
                <span>Change Password</span>
              </a>
            </li>
          )}
          <li>
            <Link to="/logout">
              <span>Logout</span>
            </Link>
          </li>
        </ul>
        <div className="mbacc_mslidersec mbacc_mslider">
          <Slider {...settingsMyAcc}>
            <div
              className={
                this.props.currentPage === "myaccount"
                  ? "mbacc_mslide active"
                  : ""
              }
            >
              <Link to="/myaccount" title="My Account">
                <span>Account Details</span>
              </Link>
            </div>
            <div
              className={
                this.props.currentPage === "myorders"
                  ? "mbacc_mslide active"
                  : ""
              }
            >
              <Link to="/myorders" title="My Orders">
                <span>Orders</span>
              </Link>
            </div>
            <div
              className={
                this.props.currentPage === "crmrewards"
                  ? "mbacc_mslide active"
                  : ""
              }
            >
              <Link to="/crmrewards" title="My Rewards">
                <span>Rewards</span>
              </Link>
            </div>
            <div
              className={
                this.props.currentPage === "mypromotions"
                  ? "mbacc_mslide active"
                  : ""
              }
            >
              <Link to="/mypromotions" title="My Promotions">
                <span>Promotions</span>
              </Link>
            </div>

            <div
              className={
                this.props.currentPage === "voucher"
                  ? "mbacc_mslide active"
                  : ""
              }
            >
              <Link to="/voucher" title="My Voucher">
                <span>Voucher</span>
              </Link>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activitycount: state.activitycount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
  };
};

Myaccount.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Myaccount)
);
