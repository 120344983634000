/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import instagram from "../../common/images/instagram.png";
import facebook from "../../common/images/facebook.png";
import youtube from "../../common/images/youtube.png";

import { GET_STATIC_BLOCK, GET_GLOBAL_SETTINGS } from "../../actions";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
var Parser = require("html-react-parser");
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { footerBlocks: "", footerlink: "", globalsettings: [] };
    this.props.getGlobalSettings();
  }

  componentDidMount() {
    this.props.getStaticBlock();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-content") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
      });
    }
    if (this.state.globalsettings !== PropsData.globalsettings) {
      if (PropsData.globalsettings.length > 0) {
        this.setState({
          globalsettings: PropsData.globalsettings[0].result_set,
        });
      }
    }
  }
  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();
    return (
      <footer>
        <div className="footer-main">
          <div className="container-full">
            <div className="footer-nav-info">
              <div className="f-nav">{this.state.footerlink}</div>
            </div>
          </div>

          <div className="mobile-footer-nav">
            <Accordion>
              <AccordionItem title="About US">
                <ul>
                  <li>
                    <a href="https://sushitei.com/" target="_blank">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="https://sushitei.com/about-us/" target="_blank">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="https://sushitei.com/our-outlets/" target="_blank">
                      Outlets
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://sushitei.com/career-with-us/"
                      target="_blank"
                    >
                      Career
                    </a>
                  </li>
                </ul>
              </AccordionItem>
              <AccordionItem title="MENU">
                <ul>
                  <li>
                    <a href="https://sushitei.com/grand-menu/" target="_blank">
                      GRAND MENU
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://sushitei.com/seasonal-menu/"
                      target="_blank"
                    >
                      SEASONAL MENU
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://sushitei.com/take-away-menu/"
                      target="_blank"
                    >
                      TAKE AWAY MENU
                    </a>
                  </li>
                  <li>
                    <a href="https://sushitei.com/promotions/" target="_blank">
                      PROMOTIONS
                    </a>
                  </li>
                </ul>
              </AccordionItem>

              <AccordionItem title="ORDER ONLINE">
                <ul>
                  <li>
                    <a
                      href="https://www.foodpanda.sg/chain/ca2bs/sushi-tei"
                      target="_blank"
                    >
                      FOODPANDA
                    </a>
                  </li>
                  <li>
                    <a href="https://il.ink/SushiTeiSGDelivery" target="_blank">
                      DELIVEROO
                    </a>
                  </li>
                  <li>
                    <a href="https://il.ink/SushiTeiSGDelivery" target="_blank">
                      GRAB
                    </a>
                  </li>
                  <li>
                    <div className="f-social">
                      <ul>
                        <li>
                          <a href="https://www.facebook.com/sushiteisg/">
                            <img
                              src={facebook}
                              className="facebook"
                              alt="facebook"
                            />
                          </a>{" "}
                        </li>
                        <li>
                          <a href="https://www.instagram.com/sushiteisg/">
                            <img
                              src={instagram}
                              className="instagram"
                              alt="instagram"
                            />
                          </a>{" "}
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/company/sushitei">
                            <img
                              src={youtube}
                              className="youtube"
                              alt="youtube"
                            />
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="copyright-section">
            <p>
              Copyright {yearSp} Sushi Tei. All rights reserved. Design By
              Jankosoft
            </p>
          </div>

          <div className="scrolltop" id="scrollbutton">
            <a href="/" className="disbl_href_action">
              <span>
                <i className="fa fa-angle-double-up ars"></i>
                <i className="fa fa-angle-up ars1"></i>
                <i className="fa fa-angle-up ars2"></i>
              </span>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
    globalsettings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
