import React, { Component } from "react";
import cookie from "react-cookies";
import { cookieConfig } from "../Helpers/Config";

class Memberlogin extends Component {
  constructor(props) {
    super(props);

    let slugtext =
      typeof this.props.match.params.slugtext !== "undefined"
        ? this.props.match.params.slugtext
        : "";
    let slugid =
      typeof this.props.match.params.slugid !== "undefined"
        ? this.props.match.params.slugid
        : "";

    if (slugtext === "success") {
      cookie.save("MemberLoginFlg", "Success", cookieConfig);
      cookie.save("MemberLoginId", slugid, cookieConfig);
    } else if (slugtext === "failure") {
      cookie.save("MemberLoginFlg", "Failure", cookieConfig);
      cookie.save("MemberLoginInfo", slugid, cookieConfig);
    }

    this.props.history.push("/");
  }

  render() {
    return <div></div>;
  }
}

export default Memberlogin;
